import * as THREE from 'three';

export default class Floor {
    constructor() {
        this.experience = window.experience;
        this.scene = this.experience.scene;
        this.debug = this.experience.debug;

        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('floor');
        }

        this.setModel();
    }

    setModel() {
        this.parameters = {
            color: '#538eac',
        };
        this.geometry = new THREE.PlaneGeometry(40, 40, 1, 1);
        this.material = new THREE.MeshStandardMaterial({
            color: this.parameters.color,
        });

        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.mesh.rotation.x = -Math.PI * 0.5;

        // Shadows
        this.mesh.receiveShadow = true;

        // Debug
        if (this.debug.active) {
            this.debugFolder.addColor(this.parameters, 'color').onChange(() => {
                this.material.color.set(this.parameters.color);
            });
        }

        this.scene.add(this.mesh);
    }
}
