import * as THREE from 'three';
import Sizes from './Utils/Sizes';
import Time from './Utils/Time';
import Camera from './Camera';
import Renderer from './Renderer';
import World from './World/World';
import Resources from './Utils/Resources';
import Debug from './Utils/Debug';
import assets from './assets';
import Controls from './Controls';

let instance = null;

export default class Experience {
    constructor(canvas) {
        // Singleton pattern
        if (instance) {
            return instance;
        }
        instance = this;

        // Global access
        window.experience = this;

        //Options
        this.canvas = canvas;

        //Setup
        this.debug = new Debug();
        this.sizes = new Sizes();
        this.time = new Time();
        this.scene = new THREE.Scene();
        this.resources = new Resources(assets);
        this.camera = new Camera();
        this.renderer = new Renderer();
        this.world = new World();
        this.controls = new Controls();

        // Sizes resize event
        this.sizes.on('resize', () => {
            this.resize();
        });

        // Time tick event
        this.time.on('tick', () => {
            this.update();
        });
    }

    resize() {
        this.camera.resize();
        this.renderer.resize();
    }

    update() {
        this.camera.update();
        this.world.update();
        this.renderer.update();
        this.controls.update();
    }

    destroy() {
        this.sizes.off('resize');
        this.time.off('tick');

        this.camera.controls.dispose();
        this.renderer.instance.dispose();

        this.scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.geometry.dispose();
            }

            for (const key in child.material) {
                const value = child.material[key];

                if (value && typeof value.dispose === 'function') {
                    value.dispose();
                }
            }
        });

        if (this.debug.active) {
            this.debug.ui.destroy();
        }
    }
}
