import * as THREE from 'three';
import Experience from '../Experience';

export default class Environment {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.renderer = this.experience.renderer;
        this.debug = this.experience.debug;

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('environment');
        }

        this.setAmbientLight();
        this.setDirectionalLight();
    }

    setAmbientLight() {
        this.ambientLight = new THREE.AmbientLight(0xffffff);
        this.scene.add(this.ambientLight);
    }

    setDirectionalLight() {
        this.directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        this.directionalLight.position.set(-10, 10, 10);

        // shadows
        this.directionalLight.castShadow = true;

        this.scene.add(this.directionalLight);

        // Debug
        if (this.debug.active) {
            this.debugFolder
                .add(this.directionalLight, 'intensity')
                .min(0)
                .max(10)
                .name('intensity');

            // helper
            const directionalLightHelper = new THREE.DirectionalLightHelper(
                this.directionalLight,
                0.5
            );
            this.scene.add(directionalLightHelper);
        }
    }

    update() {}
}
