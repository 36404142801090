import Keyboard from './Keyboard/Keyboard';
import EventEmitter from './Utils/EventEmitter';

export default class Controls extends EventEmitter {
    constructor() {
        super();

        this.experience = window.experience;
        this.time = this.experience.time;

        this.mode = 'keyboard';

        // Crane Controls
        // Orientation
        this.jibOrientation = 0;
        this.jibOrientationSpeed = 0.0015;

        // Length
        this.cableLength = 1;
        this.cableLengthMin = 1;
        this.cableLengthMax = 8.75;
        this.cableLengthSpeed = 0.002;

        // Distance
        this.cableDistance = 0.5;
        this.cableDistanceMin = 0.5;
        this.cableDistanceMax = 1.85;
        this.cableDistanceSpeed = 0.002;

        // Hook
        this.hookIsConnected = false;

        this.cableMovement = {
            z: 0,
            y: 0,
        };

        // Click
        this.lastToggle = 0;
        this.toggleDelay = 500;

        this.setKeyboard();
    }

    setKeyboard() {
        this.keyboard = new Keyboard();

        this.keyboard.on('pressed', (name) => {
            // Aldready pressed
            if (this[name]) return;

            this[name] = true;
            this.trigger(name);
        });

        this.keyboard.on('unpressed', (name) => {
            // Aldready unpressed
            if (!this[name]) return;

            this[name] = false;
        });
    }

    update() {
        if (this.mode === 'keyboard') {
            // Jib
            if (this.jibLeftPressed) {
                this.jibOrientation -=
                    this.jibOrientationSpeed * this.time.delta;
            }
            if (this.jibRightPressed) {
                this.jibOrientation +=
                    this.jibOrientationSpeed * this.time.delta;
            }

            // Cable Length
            if (this.cableDownPressed) {
                this.cableMovement.y += this.cableLengthSpeed * this.time.delta;
            }
            if (this.cableUpPressed) {
                this.cableMovement.y -= this.cableLengthSpeed * this.time.delta;
            }

            this.cableMovement.y = Math.min(
                Math.max(this.cableMovement.y, this.cableLengthMin),
                this.cableLengthMax
            );

            // Cable Distance
            if (this.cableForwardPressed) {
                this.cableMovement.z +=
                    this.cableDistanceSpeed * this.time.delta;
            }
            if (this.cableBackwardPressed) {
                this.cableMovement.z -=
                    this.cableDistanceSpeed * this.time.delta;
            }

            this.cableMovement.z = Math.min(
                Math.max(this.cableMovement.z, this.cableDistanceMin),
                this.cableDistanceMax
            );

            // Hook
            if (this.hookPressed) {
                const currentTime = Date.now();
                if (currentTime - this.lastToggle > this.toggleDelay) {
                    if (this.hookIsConnected) {
                        this.hookIsConnected = false;
                    } else {
                        this.hookIsConnected = true;
                    }
                    this.lastToggle = currentTime;
                }
            }
        }
    }
}
