import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import Experience from '../Experience';
import Crane from './Crane';
import Floor from './Floor';
import Environment from './Environment';
import Walls from './Walls';
import CannonDebugger from 'cannon-es-debugger';

export default class World {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.time = this.experience.time;
        this.resources = this.experience.resources;
        this.debug = this.experience.debug;

        this.count = 10;

        this.setWorld();

        // Wait for resources
        this.resources.on('ready', () => {
            // Setup
            this.setFloor();
            this.setCrane();
            this.setWalls();
            this.setDebugSphere();
            this.environment = new Environment();
        });

        // Axis helper
        // const axesHelper = new THREE.AxesHelper(5);
        // this.scene.add(axesHelper);
    }

    setWorld() {
        this.physics = new CANNON.World();
        this.physics.gravity.set(0, -9.82, 0);
        this.physics.allowSleep = true;

        this.physics.defaultContactMaterial.friction = 0.0005;
        this.physics.defaultContactMaterial.restitution = 0.5;

        // Debugger
        this.worldDebug = new CannonDebugger(this.scene, this.physics);
    }

    setFloor() {
        this.floor = new Floor();
        this.floor.body = new CANNON.Body({
            mass: 0,
            shape: new CANNON.Plane(),
        });

        this.floor.body.quaternion.setFromAxisAngle(
            new CANNON.Vec3(-1, 0, 0),
            Math.PI * 0.5
        );

        this.physics.addBody(this.floor.body);
    }

    setCrane() {
        this.crane = new Crane();
    }

    setWalls() {
        this.walls = new Walls();
    }

    setDebugSphere() {
        this.sphere = new THREE.Mesh(
            new THREE.SphereGeometry(0.5, 32, 32),
            new THREE.MeshStandardMaterial({
                color: 0x121212,
                metalness: 0.3,
                roughness: 0.6,
            })
        );
        this.scene.add(this.sphere);
        this.sphereBody = new CANNON.Body({
            mass: 1.2,
            shape: new CANNON.Sphere(0.5),
        });

        this.sphere.castShadow = true;
        this.sphere.receiveShadow = true;

        this.sphereBody.position.set(1.345, 5, 0);
        this.physics.addBody(this.sphereBody);
    }

    updateSphere() {
        this.sphere.position.copy(this.sphereBody.position);
        this.sphere.quaternion.copy(this.sphereBody.quaternion);
    }

    update() {
        if (this.crane) this.crane.update();

        // Step the physics physics
        this.physics.step(1 / 60, this.time.delta, 3);
        if (this.debug.active) {
            this.worldDebug.update();
        }

        if (this.sphere) this.updateSphere();
        if (this.walls) this.walls.update();

        // destroy sphere after 3 seconds
        if (this.time.elapsed > 10000 && this.sphere && this.sphereBody) {
            this.physics.removeBody(this.sphereBody);
            this.scene.remove(this.sphere);
            this.sphere.geometry.dispose();
            this.sphere.material.dispose();
        }
    }
}
