import Experience from '../Experience';
import EventEmitter from './EventEmitter';

export default class Time extends EventEmitter {
    constructor() {
        super();

        //Setup
        this.start = Date.now();
        this.current = this.start;
        this.elapsed = 0;
        this.delta = 16;
        this.experience = window.experience;

        // Debug
        this.debug = this.experience.debug;

        window.requestAnimationFrame(() => {
            this.tick();
        });
    }

    tick() {
        if (this.debug.isStatsActive) this.debug.stats.begin();

        const currentTime = Date.now();
        this.delta = currentTime - this.current;
        this.current = currentTime;
        this.elapsed = this.current - this.start;

        this.trigger('tick');

        window.requestAnimationFrame(() => {
            this.tick();
        });

        if (this.debug.isStatsActive) this.debug.stats.end();
    }
}
