import './style.css';
import { gsap } from 'gsap';
import SplitType from 'split-type';
import Experience from './Experience/Experience';

document.addEventListener('DOMContentLoaded', () => {
    window.onload = () => {
        // Title
        const pageTitle = document.title;
        const messages = [
            'Ok. I will wait you here.',
            'Will you come back?',
            "Don't worry, I'm still here.",
            'I miss you.😢',
        ];
        let blinkEvent;
        document.addEventListener('visibilitychange', function (e) {
            var isPageActive = !document.hidden;

            if (!isPageActive) {
                blink();
            } else {
                document.title = pageTitle;
                clearInterval(blinkEvent);
            }
        });

        const blink = () => {
            let i = 0;
            blinkEvent = setInterval(() => {
                document.title = messages[i];
                i++;
                if (i >= messages.length) {
                    i = messages.length - 1;
                }
            }, 2000);
        };

        // Cool Console Message
        console.log(
            `                            ,----,          
                                  ,/   .\`|          
                       ,---,    ,\`   .'  :   ,---,. 
               ,---.,\`--.' |  ;    ;     / ,'  .' | 
              /__./||   :  :.'___,/    ,',---.'   | 
         ,---.;  ; |:   |  '|    :     | |   |   .' 
        /___/ \\  | ||   :  |;    |.';  ; :   :  |-, 
        \\   ;  \\ ' |'   '  ;\`----'  |  | :   |  ;/| 
         \\   \\  \\: ||   |  |    '   :  ; |   :   .' 
          ;   \\  ' .'   :  ;    |   |  ' |   |  |-, 
           \\   \\   '|   |  '    '   :  | '   :  ;/| 
            \\   \`  ;'   :  |    ;   |.'  |   |    \\ 
             :   \\ |;   |.'     '---'    |   :   .' 
              '---" '---'                |   | ,'   
                                         \`----'     
        %cVi%cte %cThree%cJS %cTemplate by https://github.com/DooD73`,
            'color: #46caff',
            'color: #906dfe',
            'color: #bd34fe',
            'color: #ffe564',
            'color: #ff7e67'
        );

        // Entry Animation
        const sign = new SplitType('.sign');
        gsap.fromTo(
            sign.chars,
            {
                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
                y: -document.querySelector('.sign').offsetHeight,
            },
            {
                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
                y: 0,
                duration: 1,
                ease: 'bounce.out',
                stagger: {
                    each: 0.05,
                    from: 'center',
                },
            }
        );
        gsap.fromTo(
            '.socials a',
            {
                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
                y: document.querySelector('.socials').offsetHeight,
            },
            {
                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
                y: 0,
                duration: 1,
                ease: 'bounce.out',
                stagger: {
                    each: 0.05,
                    from: 'right',
                },
            }
        );

        // Experience
        const experience = new Experience(
            document.querySelector('canvas.webgl')
        );

        gsap.fromTo(
            '.key',
            {
                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
                y: document.querySelector('.keyboard-interface').offsetHeight,
            },
            {
                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
                y: 0,
                duration: 1,
                ease: 'bounce.out',
                stagger: {
                    each: 0.05,
                    axis: 'y',
                    from: 'end',
                    grid: 'auto',
                },
            }
        );
    };
});
