import * as THREE from 'three';
import * as CANNON from 'cannon-es';

export default class Walls {
    constructor() {
        this.experience = window.experience;
        this.scene = this.experience.scene;
        this.debug = this.experience.debug;

        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('walls');
        }

        // Options
        this.size = new THREE.Vector3(0.25, 0.25, 0.5);
        this.count = 4;
        this.offset = 0.005;
        this.position = new THREE.Vector3(1.3, 0, 0);
        this.rotation = new THREE.Vector3(0, 0, 0);

        this.setMaterial();
        this.setPhysicsWall();
        this.setMeshes();
    }

    setMaterial() {
        this.material = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            // wireframe: true,
        });
    }

    setPhysicsWall() {
        // Physics
        this.walls = [];
        this.physics = this.experience.world.physics;

        // Loop
        for (let i = 0; i < this.count; i++) {
            for (let j = this.count - i; j > 0; j--) {
                const size = new CANNON.Vec3(
                    this.size.x / 2,
                    this.size.y / 2,
                    this.size.z / 2
                );
                const position = new CANNON.Vec3(
                    this.position.x,
                    i * this.size.y + this.size.y / 2 + this.position.y,
                    j * (this.size.z + this.offset) -
                        ((this.count - i) * (this.size.z + this.offset)) / 2 -
                        this.size.z / 2 +
                        this.position.z
                );
                const quaternion = new CANNON.Quaternion();
                quaternion.setFromEuler(
                    this.rotation.x,
                    this.rotation.y,
                    this.rotation.z
                );

                const wall = new CANNON.Body({
                    mass: 1,
                    shape: new CANNON.Box(size),
                    position: position,
                    quaternion: quaternion,
                });

                this.walls.push(wall);
                this.physics.addBody(wall);
            }
        }
    }

    setMeshes() {
        this.meshes = [];

        this.walls.forEach((brick) => {
            const mesh = new THREE.Mesh(
                new THREE.BoxGeometry(...this.size.toArray()),
                this.material
            );

            // Copy the position and rotation of the physics body
            mesh.position.copy(brick.position);
            mesh.quaternion.copy(brick.quaternion);

            mesh.castShadow = true;
            mesh.receiveShadow = true;

            this.scene.add(mesh);
            this.meshes.push(mesh);
        });
    }

    update() {
        this.meshes.forEach((mesh, index) => {
            const body = this.walls[index];
            mesh.position.copy(body.position);
            mesh.quaternion.copy(body.quaternion);
        });
    }
}
