import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import Experience from '../Experience.js';

export default class Crane {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.time = this.experience.time;
        this.debug = this.experience.debug;
        this.controls = this.experience.controls;
        this.resources = this.experience.resources;

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('crane');
        }

        this.hookedBox = null;
        this.ray = new THREE.Raycaster();
        this.detectionDistance = 2;

        this.setMaterial();
        this.setModel();
        this.createCableCollision();
    }

    setMaterial() {
        this.color = 0xf5b81d; // yellow

        this.material = new THREE.MeshStandardMaterial();
        this.material.color = new THREE.Color(this.color);
        this.material.wireframe = false;
        this.material.metalness = 0.3;

        // Debug
        if (this.debug.active) {
            this.debugFolder
                .addColor(this, 'color')
                .name('color')
                .onChange(() => {
                    this.material.color.set(this.color);
                });

            this.debugFolder.add(this.material, 'wireframe').name('wireframe');
            this.debugFolder
                .add(this.material, 'metalness')
                .min(0)
                .max(1)
                .step(0.01)
                .name('metalness');
        }
    }

    setModel() {
        this.model = {};

        // Add the model
        this.model.group = this.resources.items.craneModel.scene;

        this.model.group.position.set(0, 0.05, 0);
        this.scene.add(this.model.group);

        // Parse the different parts
        this.model.parts = [
            {
                type: 'buttonPressure',
                regex: /jib/,
                name: 'jib',
                objects: [],
                speed: 0.002,
                easing: 0.01,
                value: 0,
                easedValue: 0,
                directionMultiplier: 1,
                min: -Infinity,
                max: Infinity,
                controlsName: 'jibOrientation',
                axis: 'y',
            },
            {
                type: '',
                regex: /cable/,
                name: 'cable',
                objects: [],
                speed: 0.002,
                easing: 0.01,
                controlsName: 'cableMovement',
                y: 0,
                easedY: 0,
                yMin: 1,
                yMax: 8.75,
                z: 0,
                easedZ: 0,
                zMin: 0.5,
                zMax: 1.85,
            },
            {
                type: 'buttonClick',
                regex: /hook/,
                name: 'hook',
                objects: [],
                connect: false,
                controlsName: 'hookIsConnected',
            },
            {
                regex: /cable_collision/,
                name: 'cableCollision',
                objects: [],
            },
        ];

        this.model.group.traverse((child) => {
            if (child instanceof THREE.Object3D) {
                const part = this.model.parts.find((part) =>
                    child.name.match(part.regex)
                );

                if (part) {
                    part.objects.push(child);
                }

                if (child instanceof THREE.Mesh) {
                    // Activate shadow
                    child.castShadow = true;
                    child.receiveShadow = true;

                    // Set the material
                    child.material = this.material;

                    if (child.name === 'hook') {
                        child.material = new THREE.MeshStandardMaterial({
                            color: 0x000000,
                        });
                    }
                }
            }
        });

        for (const _part of this.model.parts) {
            // Save as property
            this.model[_part.name] = _part;

            if (_part.type === 'buttonPressure') {
                // Input pressed event
                this.controls.on(_part.controlsName, () => {
                    _part.directionMultiplier *= -1;
                });
            }
        }
    }

    createCableCollision() {
        // create physics body
        const box = new CANNON.Box(new CANNON.Vec3(0.15, 1.5, 0.15));

        this.cableBody = new CANNON.Body({
            mass: 0,
            shape: box,
        });

        this.cableBody.collisionResponse = true; // Enable collision response
        this.experience.world.physics.addBody(this.cableBody);
        this.experience.world.cableCollision = this.cableBody;
    }

    update() {
        for (const part of this.model.parts) {
            if (part.type === 'buttonPressure') {
                part.value = this.controls[part.controlsName];

                // Clamp value
                if (part.max !== Infinity) {
                    part.value = Math.min(
                        Math.max(part.value, part.min),
                        part.max
                    );
                }

                // Apply easing
                part.easedValue += (part.value - part.easedValue) * part.easing;

                // Jib - Apply value
                if (part.name === 'jib') {
                    for (const object of part.objects) {
                        object.rotation[part.axis] = part.easedValue;
                    }
                }
            }

            // Cable - Apply value
            if (part.name === 'cable') {
                part.z = this.controls[part.controlsName].z;
                part.y = this.controls[part.controlsName].y;

                // Clamp value
                part.z = Math.min(Math.max(part.z, part.zMin), part.zMax);
                part.y = Math.min(Math.max(part.y, part.yMin), part.yMax);

                // Apply easing
                part.easedZ += (part.z - part.easedZ) * part.easing;
                part.easedY += (part.y - part.easedY) * part.easing;

                // Cable - Apply value
                for (const object of part.objects) {
                    object.scale.y = part.y;

                    if (object.children.length > 0) {
                        for (let i = 0; i < object.children.length; i++) {
                            object.children[i].scale.y = 1 / part.y;
                        }
                    }

                    object.position.z = part.z;
                }

                // Update physics body
                // change pivot point to the top of the body
                this.jibRotation = this.model.jib.easedValue; // get the jib rotation
                const radius = part.z; // calculate the radius
                this.jibX = radius * Math.sin(this.jibRotation); // calculate x position
                const yPos = -part.y / 3.6 + 4.1; // calculate y position
                this.jibZ = radius * Math.cos(this.jibRotation); // calculate z position
                // invert part.value, slow down part.value
                this.cableY = 4.75 - part.y / 2;

                this.cableBody.position.set(this.jibX, yPos, this.jibZ);

                this.cableBody.quaternion.setFromAxisAngle(
                    new CANNON.Vec3(0, 1, 0),
                    this.jibRotation
                );
            }

            if (part.type === 'buttonClick') {
                // Connect box to hook
                // calculate the distance between the hook and the nearest box
                // if the distance is less than a certain value, connect the box to the hook
                if (part.name === 'hook') {
                    part.value = this.controls[part.controlsName];

                    for (const object of part.objects) {
                        if (!this.hookedBox) {
                            // cast a ray from the hook down
                            this.ray.set(
                                new THREE.Vector3().setFromMatrixPosition(
                                    object.matrixWorld
                                ),
                                new THREE.Vector3(0, -1, 0)
                            );

                            this.meshes = this.experience.world.walls.meshes;
                            this.bodies = this.experience.world.walls.walls;

                            // check if the ray intersects with the boxes
                            const intersects = this.ray.intersectObjects(
                                this.experience.world.walls.meshes
                            );

                            if (intersects.length > 0) {
                                const distance = intersects[0].distance;

                                // change color of the box the ray intersects with
                                intersects[0].object.material =
                                    new THREE.MeshStandardMaterial({
                                        color: 0xff0000,
                                    });

                                // set white color for the boxes that are not intersecting anymore
                                for (const mesh of this.meshes) {
                                    if (mesh !== intersects[0].object) {
                                        mesh.material.color.set(0xffffff);
                                    }
                                }

                                if (
                                    distance < this.detectionDistance &&
                                    part.value
                                ) {
                                    this.hookedBox = intersects[0].object;
                                    part.connect = true;

                                    // connect the box to the hook
                                    // take the corresponding physics body and connect it to the hook
                                    const box = this.hookedBox;
                                    const boxIndex = this.meshes.indexOf(box);
                                    this.boxBody = this.bodies[boxIndex];

                                    // change boxBody mass
                                    this.boxBody.mass = 0;
                                    this.boxBody.updateMassProperties();
                                }
                            }
                        } else {
                            // change boxBody position under the hook
                            this.boxBody.position.set(
                                this.jibX,
                                this.cableY - this.cableY * 0.5,
                                this.jibZ
                            );

                            // change boxBody quaternion
                            this.boxBody.quaternion.setFromAxisAngle(
                                new CANNON.Vec3(0, 1, 0),
                                this.jibRotation + Math.PI * 0.5
                            );

                            if (!part.value) {
                                // set body to fall
                                this.boxBody.mass = 1;
                                this.boxBody.updateMassProperties();
                                this.boxBody.wakeUp();

                                this.hookedBox.material.color.set(0xffffff);
                                this.hookedBox.material.needsUpdate = true;
                                this.hookedBox = null;
                                part.connect = false;
                            }
                        }
                    }
                }
            }
        }
    }
}
