import EventEmitter from '../Utils/EventEmitter';
import KeyboardInterface from './KeyboardInterface';

export default class Keyboard extends EventEmitter {
    constructor() {
        super();

        this.setInputs();
        this.setInterface();
    }

    activate() {
        this.interface.activate();
    }

    deactivate() {
        this.interface.deactivate();
    }

    setInputs() {
        this.inputs = {};
        this.inputs.all = [
            { code: 'KeyW', name: 'cableForwardPressed' },
            { code: 'KeyA', name: 'jibLeftPressed' },
            { code: 'KeyS', name: 'cableBackwardPressed' },
            { code: 'KeyD', name: 'jibRightPressed' },
            { code: 'KeyQ', name: 'cableUpPressed' },
            { code: 'KeyE', name: 'cableDownPressed' },

            { code: 'KeyH', name: 'debugPressed' },

            { code: 'Space', name: 'hookPressed' },
        ];

        window.addEventListener('keydown', (e) => {
            const input = this.inputs.all.find(
                (input) => input.code === e.code
            );

            if (input) {
                this.trigger('pressed', [input.name]);

                this.interface.press(input);
            }
        });

        window.addEventListener('keyup', (e) => {
            const input = this.inputs.all.find(
                (input) => input.code === e.code
            );

            if (input) {
                this.trigger('unpressed', [input.name]);

                this.interface.unpress(input);
            }
        });
    }

    setInterface() {
        this.interface = new KeyboardInterface(this.inputs);
    }
}
