import './interface.css';

export default class KeyboardInterface {
    constructor(input) {
        this.inputs = input;

        this.keys = [];
        this.elements = {};

        // Container
        this.elements.container = document.createElement('div');
        this.elements.container.classList.add('keyboard-interface');
        document.body.appendChild(this.elements.container);

        // Keys
        const keys = [
            ['KeyQ', 'KeyW', 'KeyE'],
            ['KeyA', 'KeyS', 'KeyD'],
            ['Space'],
        ];

        keys.forEach((line, lineIndex) => {
            // Line element
            const lineElement = document.createElement('div');
            lineElement.classList.add('line', `line-${lineIndex}`);
            this.elements.container.appendChild(lineElement);

            // Each key
            for (const _key of line) {
                // Element
                const keyElement = document.createElement('div');
                keyElement.classList.add('key');

                if (_key) keyElement.classList.add(_key);

                lineElement.appendChild(keyElement);

                // Input
                const input = this.inputs.all.find(
                    (input) => input.code === _key
                );

                if (input) keyElement.classList.add('highlight');

                // Label
                const labelElement = document.createElement('p');

                if (_key !== 'Space') {
                    labelElement.innerText = _key.replace('Key', '');
                    keyElement.appendChild(labelElement);
                }

                // Save
                const key = {};
                key.name = _key;
                key.element = keyElement;
                this.keys.push(key);
            }
        });
    }

    activate() {
        document.body.append(this.elements.container);
    }

    deactivate() {
        document.body.removeChild(this.elements.container);
    }

    press(input) {
        const key = this.keys.find((_key) => _key.name === input.code);

        if (key) {
            key.element.classList.add('pressed');
        }
    }

    unpress(input) {
        const key = this.keys.find((_key) => _key.name === input.code);

        if (key);
        {
            window.requestAnimationFrame(() => {
                key.element.classList.remove('pressed');
            });
        }
    }
}
